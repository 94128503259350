import API from '../modules/API';
import {isEmpty} from '../modules/tools';

export default () => {
	return {
		template: require('/src/pages/profils.html'),
		data() {
			return {
				competitions: null,
				competitionsError: null,
				competitionsLoading: false,
				stages: {},
			};
		},
		async created() {
			this.getCompetitions();
		},
		mounted() {
		},
		computed: {
		},
		methods: {
			async getCompetitions() {
				this.competitionsError = null;
				this.competitionsLoading = false;

				try {
					this.competitionsLoading = true;
					this.competitions = await API.getCompetitions({$select: ['id', 'name', 'start', 'end', 'tracking', 'stages']});

					for (let competition of this.competitions) {
						for (let stage of competition.stages) {
							this.stages[stage.id] = await this.getStage(stage.id);
						}
					}
				} catch (exception) {
					this.competitionsError = exception;
				} finally {
					this.competitionsLoading = false;
				}
			},
			getStageEvents(stage) {
				let globalEvents = [
					{
						code: 'START',
						num: 0,
						event: "0",
						name: 'Départ',
						km: 0,
						type: 'S',
					},
				];
				if (stage !== null) {
					if (isEmpty(stage.events) === false) {
						globalEvents = [...globalEvents, ...stage.events];
					}
					if (isEmpty(stage.intermediates) === false) {
						globalEvents = [...globalEvents, ...stage.intermediates];
					}
					globalEvents.push({
						code: 'END',
						num: 0,
						event: "0",
						name: 'Arrivée',
						km: stage.length,
						type: 'A',
					});
				}
				globalEvents = globalEvents.filter((e) => e.hidden !== true);

				return globalEvents.sort((a, b) => {
					if (a.km > b.km) {
						return 1;
					}
					if (a.km < b.km) {
						return -1;
					}
					if (a.km === b.km) {
						return 0;
					}
				});
			},
			async getStage(stageId) {
//				this.stageError = null;
//				this.stageLoading = false;

				try {
//					this.stageLoading = true;
					const stage = await API.getStage(stageId);
					return stage;
//					document.title = this.currentStage.name;
				} catch (exception) {
//					this.stageError = exception;
				} finally {
//					this.stageLoading = false;
				}
				return {};
			},
		},
	};
};
