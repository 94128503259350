import {createRouter, createWebHistory} from 'vue-router';
import home from '../pages/home';
import competition from '../pages/competition';
import profils from '../pages/profils';

const routes = [
	{path: '/', name: 'Home', component: home()},
	{path: '/competitions/:id/:stageId?', name: 'Competition', component: competition()},
	{path: '/profils', name: 'Profils', component: profils()},
];

const router = createRouter({
	history: createWebHistory(),
	routes
});

export default router;
