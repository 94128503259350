import Config from './config';
import feathers from '@feathersjs/client';
import rest from '@feathersjs/rest-client';

const app = feathers();
const restClient = rest(Config.serverRest);
app.configure(restClient.fetch(window.fetch.bind(window)));

app.configure(feathers.authentication({
	storage: window.localStorage,
}));

export default {
	async getCompetitions(query = {}) {
		const $sort = {
			startDate: 1,
		};

		return await app.service('competitions').find({
			query: Object.assign(query, {
				$sort,
			})
		});
	},
	async getCompetition(id, query = {}) {
		return await app.service('competitions').get(id, {query});
	},
	async getStage(id, query = {}) {
		return await app.service('stages').get(id, {query});
	},
	async getTracker(id) {
		const URL = Config.serverTracker;
		const request = await window.fetch(`${URL}/api/positions/${id}`);
		return await request.json();
	},
};
