
export default () => {
	return {
		template: require('./header.html'),
		data() {
			return {
				links: [
				],
			};
		},
		async created() {
		},
		mounted() {
		},
		methods: {
		},
	};
};
