import ApexCharts from 'apexcharts';
import {isEmpty, formatComma, eventIcon} from '../modules/tools';

const parseFloat = (value, precision) => {
	return formatComma((Math.round(value * 100) / 100).toFixed(precision));
};

export default (template) => {
	return {
		props: {
			stageEvents: Object,
			currentStage: Object,
		},
		template,
		watch: {
			'currentStage'() {
				// necessary timeout
				setTimeout(() => {
					this.drawChart();
				}, 0);
			},
		},
		data() {
			return {
				loading: false,
				chart: null,
			};
		},
		mounted() {
			this.drawChart();
		},
		methods: {
			drawChart() {
				this.loading = true;

				if (this.chart !== null) {
					this.chart.destroy();
					this.chart = null;
				}

				if (isEmpty(this.currentStage) === true) {
					console.log('Invalid stage');
					this.loading = false;
					return;
				}
				if (isEmpty(this.currentStage.roads) === true) {
					console.log('Invalid stage roads');
					this.loading = false;
					return;
				}

				const labels = this.currentStage.roads.map(e => e.startKilo);
				const elevationData = this.currentStage.roads.map(e => e.elevation);
				const maxElevation = Math.max(...elevationData, 1200);

				const liveCurrentKm = 0;
				const elevationData1 = elevationData.slice(0, liveCurrentKm);
				const elevationData2 = [...Array.from({length: liveCurrentKm - 1}, () => 0), ...elevationData.slice(liveCurrentKm, elevationData.length)];

				const points = [];

				const stageEvents = this.stageEvents.filter((e) => ['START', 'END'].includes(e.code) === false);

				for (let event of stageEvents) {
					const y = this.currentStage.roads.find(e => e.startKilo >= event.km) || {elevation: 0};
					const icon = eventIcon(event.category, event.code);

					const newPoint = {
						id: event.id,
						x: event.km,
						y: y.elevation,
						// we do not need marker as we use image
						marker: {
							size: 0,
//							fillColor: eventColorBg(event.code),
//							strokeColor: 'white',
						},
//						label: {
//							text: event.name,
//							offsetY: 0,
//							style: {
//								color: 'white',
//								background: eventColorBg(event.code),
//							},
//						},
						click: (e) => {
							//console.log('click', e);

							const scroll2El = document.getElementById(`event-${e.id}`);
							if (scroll2El) {
								scroll2El.scrollIntoView({
									behavior: 'smooth',
								});
							}
						},
					};

					if (icon !== '') {
						newPoint.image = {
							path: icon,
							offsetY: -15,
						};
					}

					const prevPointsAtSameElevation = points.find((p) => p.x === event.km);
					if (typeof prevPointsAtSameElevation !== 'undefined' && typeof prevPointsAtSameElevation.image !== 'undefined' && typeof newPoint.image !== 'undefined') {
						newPoint.image.offsetY = prevPointsAtSameElevation.image.offsetY - 20;
					}

					points.push(newPoint);
				}

				const options = {
					chart: {
						height: 200,
						type: 'area',
						toolbar: {
							show: false,
						},
						zoom: {
							enabled: false,
						},
						animations: {
							enabled: false,
						},
					},
					// color the line
					colors: ['black'],
					series: [{data: elevationData1}, {data: elevationData2}],
					legend: {
						show: false,
					},
					// OK => KM
					xaxis: {
						type: 'numeric',
						tickAmount: 10,
						categories: labels,
						// OK // how many decimals
						decimalsInFloat: 1,
						axisTicks: {
							show: false,
						},
						labels: {
							formatter: (e) => {
								return parseFloat(e, 1) + " km";
							},
						},
					},
					// OK: elevation
					yaxis: {
						type: 'numeric',
						min: 0,
						max: maxElevation,
						labels: {
							formatter: (e) => {
								return parseInt(e) + " m";
							},
						},
					},
					stroke: {
						curve: 'smooth', // OK
						width: 2, // OK
					},
					fill: {
						type: 'gradient', // OK
						gradient: {
//							shade: 'dark',
//							type: 'horizontal',
//							shadeIntensity: 0.5,
//							inverseColors: true,
							opacityFrom: 0.8,
							opacityTo: 0.4,
						},
						colors: ['orange', 'red'],
					},
					// OK, labels on every points of the line
					dataLabels: {
						enabled: false,
					},
					annotations: {
						// OK
//						xaxis: [
//							{
//								x: 122,
//								borderColor: 'black',
//								label: {
//									borderColor: 'black',
//									style: {
//										color: '#fff',
//										background: 'red'
//									},
//									text: 'Tête de la course',
//								}
//							}
//						],
						// OK
						points,
					},
					// OK
					grid: {
						show: true,
						borderColor: '#94949435',
						strokeDashArray: 10,
					},
					tooltip: {
						enabled: false,
					},
				};

				this.chart = new ApexCharts(document.querySelector(`#chart-${this.currentStage.id}`), options);
				this.chart.render();

				setTimeout(() => {
					this.loading = false;
				}, 500);
			},
		},
	};
};
